import React from "react";
import Picture from "./picture";

const GallerySection = (props) => {
  const { focusedPictureData, filteredPictures, setFocus } = props;

  return (
    <div
      className={"gallery"}
      style={{
        display: "flex",
        flex: 1,
        flexWrap: "wrap",
        justifyContent: "center",
        maxHeight: "min-content",
        overflowX: "hidden",
        pointerEvents: focusedPictureData && "none",
      }}
    >
      {/*{filteredPictures.map((p, index) => (
        <Picture
          key={`picture-${index}`}
          data={p}
          setFocus={setFocus}
          isLast={filteredPictures.length - 1 === index}
        />
      ))}*/}
      <p
        style={{
          color: "white",
          padding: "2px 4px",
          backgroundColor: "rgba(0,0,0,0.5)",
          textAlign: "center",
        }}
      >
        Webseite wird zurzeit überarbeitet.
        <br />
        <br />
        Schauen Sie zwischenzeitlich doch auf{" "}
        <a
          className={"insta-link"}
          href={"https://www.instagram.com/elvirakurzmann/"}
        >
          Instagram
        </a>{" "}
        vorbei!
      </p>
    </div>
  );
};

export default GallerySection;
